import { graphql } from "gatsby"
import Layout, { IMetadata } from "../components/Layout"
import React from "react"
import Section from "../components/ui/Section/Section";
import Blocks from "../components/blocks/Blocks";
import * as styles from './leistungen.module.scss';

export default ({ data }) => {
    const weiterverarbeitung: any = data.weiterverarbeitungHJson

    const crumbs = [
        { label: "Home", url: "" },
        { label: "Leistungen", url: "leistungen" },
        { label: "Weiterverarbeitung", url: "weiterverarbeitung"},
        { label: weiterverarbeitung.name, url: weiterverarbeitung.url },
    ];

    // Create metadata object
    const metaData: IMetadata = {};
    metaData.description = weiterverarbeitung.description;
    metaData.ogImage = weiterverarbeitung.listenbild.src.childImageSharp.gatsbyImageData.images.fallback.src;
    metaData.ogTitle = weiterverarbeitung.name + " bei HIS Print & Service";

    return (
        <Layout crumbs={crumbs} metadatas={metaData}>
            <Section size="extraSmall"  className={styles.headlineWrapper}>
                <h1>{weiterverarbeitung.name}</h1>
                <p className="subtitle">{weiterverarbeitung.description}</p>
                {weiterverarbeitung.text && <p>{weiterverarbeitung.text}</p>}
                
            </Section>
           <Blocks blocks={weiterverarbeitung.blocks} />
        </Layout>
    )
}

export const weiterverarbeitungQuery = graphql`query weiterverarbeitungQuery($url: String!) {
  weiterverarbeitungHJson(url: {eq: $url}) {
    name
    type
    url
    listenbild {
      alt
      src {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    blocks {
      ...BlocksFragment
    }
    description
  }
}
`
